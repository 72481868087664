import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/types/query-keys';
import { getClientPublicRuntimeConfig } from '../utils/client-public-runtime-config';
import { type AppVersion } from '@/app/api/version/route';

const getAppVersion = async (): Promise<AppVersion> => {
   const response = await fetch('/api/version', {
      // Omit cookies to enable caching.
      credentials: 'omit',
   });
   return response.text();
};

const APP_VERSION_INTERVAL_TIME = 1000 * 60 * 1; // 1 minute

export const useAppVersionQuery = (options?: UseQueryOptions<AppVersion>) => {
   return useQuery<AppVersion>({
      queryKey: queryKeys.appVersion,
      queryFn: getAppVersion,
      staleTime: APP_VERSION_INTERVAL_TIME,
      refetchInterval: APP_VERSION_INTERVAL_TIME,
      // Initial version will defer the first request to the server.
      // The version on the first load is expected to match bundled version.
      // This is an optimization to avoid an unnecessary request.
      initialData: getClientPublicRuntimeConfig().appVersion,
      ...options,
   });
};
