import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useWeeklyPauseRequiredFlows = () => {
   const { data: flows, ...rest } = useQuery({
      queryKey: queryKeys.weeklyPauseRequiredFlows,
      queryFn: async () => {
         const response =
            await webApiClient.moodTracking.weeklyPauseRequiredFlows();
         return response.body;
      },
   });

   return {
      flows: flows ?? null,
      ...rest,
   };
};

// const { data: weeklyPauseData, status } = useQuery(
//    ['weekly-pause-info'],
//    () => {
//       return apiClient.getWeeklyPauseRequiredFlows();
//    },
//    {
//       onError: (err) => {
//          handleSentryException(err);
//          toastError('Error getting the weekly pause info.');
//       },
//    }
// );
