import { Circle, Flex, Text, useBoolean } from '@chakra-ui/react';
import { useRouter, useSearchParams } from 'next/navigation';

import DailyMoodTrackingModal from '../Modals/DailyMoodTrackingModal';
import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useThemedToast from '@/hooks/useThemedToast';
import { HorizontalCard } from '../HorizontalCard/HorizontalCard';
import { type FlexProps } from '@chakra-ui/layout';
import Link from 'next/link';
import { Icon } from '../Icon';
import { JourneyTabs } from '@/app/(private)/(side-nav)/journey/journey-page';

export const SimpleMoodTracking = ({
   didTrackToday,
   ...rest
}: {
   didTrackToday: boolean | undefined;
} & FlexProps) => {
   const [dailyMoodTrackDate, setDailyMoodTrackDate] = useState<string | null>(
      null,
   );

   const searchParams = useSearchParams();
   const router = useRouter();
   const { toastSuccess } = useThemedToast();

   const [
      isMoodTrackingModalOpen,
      { on: openMoodTrackingModal, off: closeMoodTrackingModal },
   ] = useBoolean();

   const handleOpenMoodTrackingModalWithDate = useCallback(
      (date: string) => {
         setDailyMoodTrackDate(date);
         openMoodTrackingModal();
      },
      [openMoodTrackingModal],
   );

   useEffect(() => {
      if (
         searchParams.get('open') === 'daily-mood-tracking' ||
         searchParams.get('action') === 'track-daily-mood'
      ) {
         if (!didTrackToday) {
            handleOpenMoodTrackingModalWithDate(DateTime.local().toISODate());
         } else {
            toastSuccess("You've already tracked your daily mood today!");
         }

         const nextSearchParams = new URLSearchParams(searchParams.toString());
         nextSearchParams.delete('open');
         nextSearchParams.delete('action');

         router.replace(`/?${nextSearchParams.toString()}`);
      }
   }, [
      didTrackToday,
      handleOpenMoodTrackingModalWithDate,
      router,
      searchParams,
      toastSuccess,
   ]);

   return (
      <Flex flexDir="column" gap={{ base: 4, lg: 6 }} {...rest}>
         <HorizontalCard
            title="Track Your Mood"
            footerContent={
               didTrackToday ? (
                  <Flex
                     as={Link}
                     href={`/journey?tab=${JourneyTabs.Moods}`}
                     alignItems="center"
                     gap={2}
                  >
                     <Text>View past mood logs</Text>
                     <Icon name="arrow-right" boxSize={4} />
                  </Flex>
               ) : null
            }
         >
            <Text>{DateTime.now().toFormat('cccc')}</Text>
            <Circle size="3px" bg="text.primary" />
            <Text>{DateTime.now().toFormat('dd LLL')}</Text>

            <HorizontalCard.Button
               aria-label="Track your mood"
               iconName={didTrackToday ? 'checkmark' : 'plus-thin'}
               bg={didTrackToday ? 'accent.green' : 'accent.orange'}
               isDisabled={
                  didTrackToday || typeof didTrackToday === 'undefined'
               }
               _hover={{
                  bg: didTrackToday ? 'accent.green' : 'accent.orange',
               }}
               onClick={openMoodTrackingModal}
               isLoading={typeof didTrackToday === 'undefined'}
            />
         </HorizontalCard>

         <DailyMoodTrackingModal
            isOpen={isMoodTrackingModalOpen}
            onClose={closeMoodTrackingModal}
            w="full"
            date={dailyMoodTrackDate}
         />
      </Flex>
   );
};
