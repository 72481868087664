import {
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Badge,
   type BadgeProps,
   Box,
   Button,
   Drawer,
   DrawerOverlay,
   DrawerCloseButton,
   DrawerContent,
   Flex,
   Heading,
   Text,
   useBoolean,
   DrawerBody,
   Skeleton,
} from '@chakra-ui/react';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { Icon } from '../Icon';
import { BottomRightCircles } from '../Images';
import { motion } from 'framer-motion';
import { useSearchParams } from 'next/navigation';
import { useWeeklyPauseRequiredFlows } from '@/hooks/react-query/useWeeklyRequiredFlows';
import { NextImage } from '@innerwell/chakra';

const StyledBadge = (props: BadgeProps) => {
   return (
      <Badge
         bg="background.primary"
         color="white"
         rounded="full"
         fontWeight={600}
         px="3.5"
         py="1"
         {...props}
      />
   );
};

export const WeeklyTrackAccordion = () => {
   const searchParams = useSearchParams();

   const { flows } = useWeeklyPauseRequiredFlows();

   const isWeeklyPauseFinished =
      searchParams.get('weekly-pause-finished') === '1';

   const [isDrawerOpen, { on: openDrawer, off: closeDrawer }] = useBoolean();

   if (isWeeklyPauseFinished) {
      return null;
   }

   return (
      <>
         <Box
            as={motion.div}
            sx={{
               '--height': {
                  base: '20.25rem',
                  lg: '15.625rem',
               },
            }}
            initial={{
               opacity: 0,
               height: 0,
            }}
            animate={{
               opacity: 1,
               height: 'auto',
            }}
            pos="relative"
            zIndex={4}
         >
            <Flex
               flexDir="column"
               position="absolute"
               top="-1.62rem"
               left={5}
               alignItems="center"
               justifyContent="center"
               zIndex={4}
            >
               <Icon
                  name="vertical-dashed-line"
                  h={12}
                  w="1px"
                  color="background.primary"
               />

               <Box
                  boxSize=".7rem"
                  bg="background.primary"
                  rounded="full"
                  opacity={1}
                  flexShrink={0}
                  flexGrow={0}
               />
            </Flex>

            <Accordion allowToggle defaultIndex={[0]}>
               <AccordionItem
                  border="none"
                  borderRadius="12px"
                  boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
               >
                  {({ isExpanded }) => (
                     <>
                        <h2>
                           <AccordionButton
                              px={{ base: 3, lg: 4 }}
                              minH="56px"
                              pl={{ base: 10, lg: '42px' }}
                              bg="background.secondary"
                              _hover={{
                                 bg: 'background.secondary',
                              }}
                              borderRadius="12px"
                              borderBottomLeftRadius={isExpanded ? 0 : '12px'}
                              borderBottomRightRadius={isExpanded ? 0 : '12px'}
                              pos="relative"
                              borderBottom="1px solid"
                              borderColor="rgba(65, 119, 119, 0.1)"
                           >
                              <Text size="eyebrow" color="text.primary">
                                 Weekly reflection
                              </Text>
                              <Icon
                                 name="chevron-down-light"
                                 w={5}
                                 h={5}
                                 color="text.primary"
                                 ml="auto"
                                 transition="transform .2s ease"
                                 transform={
                                    isExpanded
                                       ? 'rotate(180deg)'
                                       : 'rotate(0deg)'
                                 }
                                 pos="absolute"
                                 right={{ base: 3, lg: 4 }}
                              />
                           </AccordionButton>
                        </h2>
                        <AccordionPanel
                           pt={4}
                           pb={6}
                           px={6}
                           bg="background.secondary"
                           borderBottomLeftRadius="12px"
                           borderBottomRightRadius="12px"
                           color="text.primary"
                           pos="relative"
                        >
                           <Skeleton
                              as={Flex}
                              isLoaded={Boolean(flows)}
                              mb={3}
                              gap={2}
                              justifySelf="flex-start"
                              rounded="full"
                              minW="8.125rem"
                           >
                              <StyledBadge>KEQ</StyledBadge>
                              {Boolean(flows?.phq9) && (
                                 <StyledBadge>PHQ-9</StyledBadge>
                              )}
                              {Boolean(flows?.gad7) && (
                                 <StyledBadge>GAD-7</StyledBadge>
                              )}
                              {Boolean(flows?.pcl5) && (
                                 <StyledBadge>PCL-5</StyledBadge>
                              )}
                           </Skeleton>
                           <Text mb={4}>
                              These tailored questionnaires provide valuable
                              insight into your treatment progress. Regularly
                              measuring your progress enables you and your care
                              team to work together to make personalized
                              adjustment to your care plan.
                           </Text>

                           <Flex gap={4}>
                              <NextLinkButton
                                 w="auto"
                                 minW="8.125rem"
                                 size="sm"
                                 href="/weekly-pause"
                              >
                                 Begin
                              </NextLinkButton>
                              <Button
                                 size="sm"
                                 variant="link"
                                 onClick={openDrawer}
                              >
                                 Find out more
                              </Button>
                           </Flex>

                           <BottomRightCircles
                              pos="absolute"
                              right={0}
                              bottom={0}
                              display={{
                                 base: 'none',
                                 lg: 'block',
                              }}
                           />
                        </AccordionPanel>
                     </>
                  )}
               </AccordionItem>
            </Accordion>
         </Box>

         <Drawer
            size="md"
            isOpen={isDrawerOpen}
            placement="right"
            onClose={closeDrawer}
         >
            <DrawerOverlay />
            <DrawerContent maxW="sideDrawer" pos="relative">
               <DrawerCloseButton
                  mt={5}
                  size="lg"
                  borderRadius="86px"
                  backgroundColor="neutral.200"
                  pos="absolute"
                  right={{ base: 6, lg: 8 }}
                  top={{ base: 2, lg: 4 }}
               />

               <DrawerBody
                  pt={0}
                  pb={6}
                  px={{ base: 5, lg: 10 }}
                  mt={20}
                  display="flex"
                  flexDir="column"
               >
                  <Heading size="small" pr={{ lg: 2 }} mb={1}>
                     Tracking your weekly progress
                  </Heading>
                  <Flex flexDir="column" gap={4}>
                     <Flex mt={6} gap={4} alignItems="center">
                        <NextImage
                           w={{
                              base: '60px',
                              lg: '80px',
                           }}
                           h={{
                              base: '60px',
                              lg: '80px',
                           }}
                           src="/images/ben-medrano-peach.jpg"
                           alt="Ben Medrano"
                           borderRadius="50%"
                        />
                        <Box textAlign="left" color="text.secondary">
                           <Text fontWeight={500}>Dr. Ben Medrano</Text>
                           <Text>Chief Medical Officer</Text>
                        </Box>
                     </Flex>

                     <Flex flexDir="column" color="text.secondary" gap={1}>
                        <Text size="body" fontWeight={600}>
                           KEQ (Ketamine Experience Questionnaire)
                        </Text>
                        <Text>
                           This questionnaire helps assess your personal
                           experiences with ketamine treatment, focusing on its
                           emotional, physical, and psychological effects. It
                           provides insight into how the treatment is impacting
                           your journey and guides your care team in maximizing
                           its benefits.
                        </Text>
                     </Flex>

                     {Boolean(flows?.phq9) && (
                        <Flex flexDir="column" color="text.secondary" gap={1}>
                           <Text size="body" fontWeight={600}>
                              PHQ-9 (Patient Health Questionnaire)
                           </Text>
                           <Text>
                              The PHQ-9 measures the severity of depressive
                              symptoms and tracks changes over time. It helps
                              your care team monitor your mental health and make
                              necessary adjustments to your treatment plan.
                           </Text>
                        </Flex>
                     )}

                     {Boolean(flows?.gad7) && (
                        <Flex flexDir="column" color="text.secondary" gap={1}>
                           <Text size="body" fontWeight={600}>
                              GAD-7 (Generalized Anxiety Disorder Scale)
                           </Text>
                           <Text>
                              This tool evaluates the intensity of anxiety
                              symptoms and their effect on daily life. It
                              ensures your care team can identify trends and
                              address your specific needs effectively.
                           </Text>
                        </Flex>
                     )}

                     {Boolean(flows?.pcl5) && (
                        <Flex flexDir="column" color="text.secondary" gap={1}>
                           <Text size="body" fontWeight={600}>
                              PCL-5 (PTSD Checklist)
                           </Text>
                           <Text>
                              The PCL-5 screens for symptoms of PTSD, helping to
                              evaluate their frequency and severity. This allows
                              your care team to tailor support strategies to
                              better manage trauma-related challenges.
                           </Text>
                        </Flex>
                     )}
                  </Flex>
               </DrawerBody>
            </DrawerContent>
         </Drawer>
      </>
   );
};
