'use client';

import { isRN } from '@/services/rn/utils/is-rn';
import { useEffect, useState, type PropsWithChildren } from 'react';

export const ShowRN = ({ children }: PropsWithChildren) => {
   const [isClient, setIsClient] = useState(false);

   useEffect(() => {
      setIsClient(true);
   }, []);

   if (!isClient || !isRN()) {
      return null;
   }

   return children;
};
