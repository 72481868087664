type RecommendationLink = {
   title: string;
   link: string;
   imageSrc: string;
};

export const medicalConsultRecommendationLinks: RecommendationLink[] = [
   {
      title: 'Ketamine 101',
      link: 'https://helloinnerwell.com/reflections/ketamine-101',
      imageSrc:
         'https://helloinnerwell.com/_next/image?url=https%3A%2F%2Fimages.prismic.io%2Finnerwell%2Fa368da64-e9d4-416d-bbf5-6c7c66019c4e_Ketamine%2B101.webp%3Fauto%3Dcompress%2Cformat&w=3840&q=75',
   },
   {
      title: 'How to Prepare for your Ketamine Experience',
      link: 'https://helloinnerwell.com/reflections/how-to-prepare-for-ketamine-experience',
      imageSrc:
         'https://helloinnerwell.com/_next/image?url=https%3A%2F%2Fimages.prismic.io%2Finnerwell%2Fac9731e7-3683-4623-a0f5-1fb9de8e744c_how-to-prepare-ketamine-experience.jpeg%3Fauto%3Dcompress%2Cformat&w=3840&q=75',
   },
   {
      title: 'How to Find a Sitter',
      link: 'https://helloinnerwell.com/reflections/how-to-find-a-sitter',
      imageSrc:
         'https://helloinnerwell.com/_next/image?url=https%3A%2F%2Fimages.prismic.io%2Finnerwell%2FZw93M4F3NbkBXfe2_pexels-cottonbro-5486098.jpg%3Fauto%3Dformat%2Ccompress&w=3840&q=75',
   },
   {
      title: 'How We Keep You Safe at Innerwell',
      link: 'https://helloinnerwell.com/reflections/how-we-keep-you-safe-at-innerwell ',
      imageSrc:
         'https://helloinnerwell.com/_next/image?url=https%3A%2F%2Fimages.prismic.io%2Finnerwell%2F3ddf65c2-c89b-4c9a-af8c-d12eaad51645_ketamine_assisted_psychotherapy.jpg%3Fauto%3Dformat%2Ccompress&w=3840&q=75',
   },
];
