import { isClient } from '@/services/env/utils/runtime-environments';

declare global {
   interface Window {
      ReactNativeWebView?: {
         postMessage: (message: string) => void;
      };
   }
}

export const isRN = () => isClient() && !!window.ReactNativeWebView;
